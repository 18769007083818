import React, { useState, Fragment } from "react";

// Libraries
import { connect } from "react-redux";
import { createSelector } from "reselect";

// Core
// import { db } from "../../../core/firebase";
import { getSession } from "../../../core/session";
import { setNotification } from "../../../core/notifications";

// Component
import Image from "../../components/images/image";
import FormMeedenken from "../../components/forms/meedenken";
import FormAanvulling from "../../components/forms/aanvulling";

const Completed = ({ session, setNotification }) => {
    const [openMeedenken, toggleMeedenken] = useState(false);
    const [openAanvulling, toggleAanvulling] = useState(false);

    return (
        <Fragment>
            <div className="flex flex-center pt-l">
                <div className="container container-m center pt-l pb-l">
                    <div className="flex flex-center flex-align">
                        <Image
                            height="160px"
                            src={`${process.env.PUBLIC_URL}/logos/logo-samen-kerschoten.png`}
                        />
                    </div>
                    <div className="flex flex-center relative">
                        <p className="center text-20 pb-m pt-m">
                            Bedankt voor uw mening over uw buurt en
                            leefomgeving. De resultaten zullen snel met u
                            gedeeld worden.
                        </p>

                        <p className="center text-20 mt-m">
                            Wilt u graag meedenken over bepaalde thema's?
                        </p>
                        <button
                            className="btn full mt mb-m"
                            onClick={() => toggleMeedenken(!openMeedenken)}
                        >
                            <span className="fs-xs">Klik hier</span>
                        </button>
                        <p className="center text-20">
                            Heeft u iets gemist, wilt u aanvullingen doen of een
                            goed idee aandragen?
                        </p>
                        <button
                            className="btn full mt mb-m"
                            onClick={() => toggleAanvulling(!openAanvulling)}
                        >
                            <span className="fs-xs">Klik hier</span>
                        </button>
                    </div>
                </div>
            </div>
            <FormMeedenken
                open={openMeedenken}
                toggle={() => toggleMeedenken(!openMeedenken)}
            />

            <FormAanvulling
                open={openAanvulling}
                toggle={() => toggleAanvulling(!openAanvulling)}
            />
        </Fragment>
    );
};

const state = createSelector(getSession, (session) => ({ session }));
export default connect(state, { setNotification })(Completed);
