import React from "react";

// Components
import Image from "../images/image";

function Navigation() {
    return (
        <nav className="navigation container  flex flex-align flex-space">
            <Image
                height="80px"
                className="navigation-banner"
                src={`${process.env.PUBLIC_URL}/logos/logo-samen-kerschoten.png`}
            />
            <Image
                height="64px"
                width="64px"
                src={`${process.env.PUBLIC_URL}/logos/Logo - Baas boven Baas_1@4x.png`}
            />
        </nav>
    );
}

export default Navigation;
