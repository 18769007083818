export const ages = [
    { value: "17 of jonger", label: "17 of jonger" },
    { value: "18-20", label: "18-20" },
    { value: "21-29", label: "21-29" },
    { value: "30-39", label: "30-39" },
    { value: "40-49", label: "40-49" },
    { value: "50-59", label: "50-59" },
    { value: "60-69", label: "60-69" },
    { value: "70 of ouder", label: "70 of ouder" },
];

export const arrangements = [
    { value: "Ik heb een koophuis", label: "Ik heb een koophuis" },
    {
        value: "ik huur van een particulier",
        label: "ik huur van een particulier",
    },
    {
        value: "ik huur van de woningbouwcoöperatie",
        label: "ik huur van de woningbouwcoöperatie",
    },
];
